import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {APP_BASE_HREF} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpModule} from '@angular/http';

// Providers
// import {Title} from '@angular/platform-browser';
// import {MetaService} from 'ng2-meta';
import {CoreModule} from './core/core.module';
import {store} from './core/store/store';

// App stuff
import {AppComponent} from './app.component';
import {ToolbarComponent} from './shared/components/toolbar/toolbar.component';

import {HomeModule} from './+home/home.module';
import {ResourceModule} from './+resource/resource.module';
import {CategoryModule} from './+category/category.module';
// import {SubmitResourceModule} from './+submit-resource/submit-resource.module';
import {PageModule} from './+page/page.module';
import {SearchModule} from './+search/search.module';
import {SidebarModule} from './+sidebar/sidebar.module';
import {ResourceStackModule} from './+resource-stack/resource-stack.module';


// import {ReadMorePipe} from './shared/pipes/readmore.pipe';
import {OrderByPipe} from './shared/pipes/orderby.pipe';
// import {KarenKeyboardComponent} from '../../../+karen-keyboard/karen-keyboard.component';

import {routes} from './app.routes';

let routerModule = RouterModule.forRoot(routes);

@NgModule({
  imports: [
    BrowserModule,
    HttpModule,
    CoreModule,
    routerModule,
    ResourceModule,
    HomeModule,
    CategoryModule,
    PageModule,
    SearchModule,
    SidebarModule,
    // SubmitResourceModule,
    ResourceStackModule,
    CoreModule
    // Title,
    // MetaService
  ],
  providers: [store],
  declarations: [AppComponent,
    ToolbarComponent,
    // ReadMorePipe,
    OrderByPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
